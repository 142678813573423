import {
  DropdownWithRound,
  DropdownWithRoundProps
} from '@smu-chile/pkg-unimarc-components'
import { addToHomeData, clickedPromos } from '@smu-chile/pkg-unimarc-hooks'
import {
  SendPromoDataImageBanner,
  SendPromoDataProps
} from 'shared/interfaces/Home'

interface IDropdownWithRoundContent extends DropdownWithRoundProps {
  isIntersecting: boolean
}

export const DropdownWithRoundContent = ({
  cfIndex,
  dataBody,
  orderForm,
  handleChangeDropDown,
  isIntersecting,
  isOpen,
  isMobile,
  linkWrapper,
  site,
  showMore,
  title
}: IDropdownWithRoundContent) => {
  const clickedBanner = ({ data, index }) => {
    clickedPromos({
      ...data,
      locationId: `${cfIndex} - ${index + 1}`,
      urlPath: data?.['href'],
      site,
      orderForm
    })
  }

  const sendPromoData = ({ cfIndex, data, ref }: SendPromoDataProps) => {
    let promoData: SendPromoDataImageBanner = data
    Array.isArray(dataBody) &&
      dataBody.map((item, index) => {
        if (item?.name?.toUpperCase() === ref?.alt?.toUpperCase()) {
          promoData = {
            creativeName: item?.creativeName,
            creativeSlot: item?.creativeSlot,
            locationId: `${cfIndex} - ${index + 1}`,
            name: item?.name,
            promotionId: item?.promotionId,
            promotionName: item?.promotionName,
            urlPath: item?.['href']
          }
          addToHomeData({ ref, promoData: { ...promoData } })
        }
      })
  }

  return (
    <>
      {isIntersecting && (
        <DropdownWithRound
          catchPromoData={(promoData?: object) => {
            sendPromoData({
              cfIndex: `${cfIndex}`,
              data: dataBody,
              ref: promoData
            })
          }}
          dataBody={dataBody}
          handleChangeDropDown={handleChangeDropDown}
          handleOnRedirect={clickedBanner}
          isMobile={isMobile}
          isOpen={isOpen}
          linkWrapper={linkWrapper}
          showMore={showMore}
          title={title}
        />
      )}
    </>
  )
}
