import {
  LocalProductsBanner,
  LocalProductsBannerProps
} from '@smu-chile/pkg-unimarc-components'

interface ILocalProductsBannerContent extends LocalProductsBannerProps {
  isIntersectingProductsBanner: boolean
}

export const LocalProductsBannerContent = ({
  backgroundColor,
  buttonLabel,
  description,
  descriptionFontColor,
  imagesSrcs,
  isIntersectingProductsBanner,
  linkWrapper,
  title,
  titleFontColor,
  onClick
}: ILocalProductsBannerContent) => {
  return (
    <>
      {isIntersectingProductsBanner && (
        <LocalProductsBanner
          backgroundColor={backgroundColor}
          buttonLabel={buttonLabel}
          description={description}
          descriptionFontColor={descriptionFontColor}
          imagesSrcs={imagesSrcs}
          linkWrapper={linkWrapper}
          onClick={onClick}
          title={title}
          titleFontColor={titleFontColor}
        />
      )}
    </>
  )
}
