import { BannersLayoutEnum } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { getParamsInURL } from '@smu-chile/pkg-unimarc-hooks'
import { isProd } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import router from 'next/router'

export const getBannerImages = (bannerImages, contentObject) => {
  const paramsApp = getParamsInURL('source')
  const pathMembershipLanding = isProd ? 'membresia' : 'membresia-prueba'

  return bannerImages.map((images) => {
    const collectionName = images['collectionName']
      ? images['collectionName']
      : null
    return {
      url: images[contentObject]['fields']['file']['url'],
      alt: images['fields']?.['alt'],
      ...(images['collectionName'] && {
        collectionName: images['collectionName']
      }),
      ...(images['reference'] && {
        onClick: () => {
          paramsApp
            ? router.push(
                `${pathMembershipLanding}?source=APP&collectionName=${collectionName}`
              )
            : router.push(images['reference'])
        }
      })
    }
  })
}

export const getBannerItemsToShow = (
  layout: string,
  itemsToShow: number
): number | undefined => {
  if (layout === BannersLayoutEnum.Carousel) {
    return itemsToShow
  }

  return undefined
}

export const getBannerShowDots = (
  layout: string,
  showDots: boolean
): boolean | undefined => {
  if (layout === BannersLayoutEnum.Carousel) {
    return showDots
  }

  return undefined
}

export const getContainerBackgroundColor = (
  layout: string,
  backgroundColor: string
) => {
  if (
    layout === BannersLayoutEnum.Carousel ||
    layout === BannersLayoutEnum.OccasionOfConsumption
  ) {
    return backgroundColor
  }

  return 'none'
}

export const getContainerMaxWidth = (layout: string, expand: boolean) => {
  if (expand && layout === BannersLayoutEnum.Carousel) {
    return '100%'
  }

  return getGlobalStyle('--width-max-desktop')
}

export const getContainerPadding = (layout: string, expand: boolean) => {
  if (expand && layout === BannersLayoutEnum.Carousel) {
    return '0 0 32px'
  }

  return '0'
}

export const getRowPadding = (
  layout: string,
  mobile: boolean,
  logged: boolean,
  index: number
) => {
  if (index === 1 && layout === BannersLayoutEnum.OnlyOne) {
    if (mobile) return '0 12px'
    if (logged) return '9px 12px 0 12px'
    return '12px'
  }

  if (layout === BannersLayoutEnum.OccasionOfConsumption) return '0'
  if (mobile) return '0 12px'
  return '0 12px 0'
}

export const getTitlePadding = (layout: string, mobile: boolean) => {
  if (layout === BannersLayoutEnum.OccasionOfConsumption) {
    return mobile ? '15px 12px' : '15px 20px'
  }

  if (layout === BannersLayoutEnum.Carousel) {
    return mobile ? '12px' : '0 20px 20px'
  }

  return mobile ? '12px' : '0 20px 20px'
}
